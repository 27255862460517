.menu {
    padding: 20px 0;
    float: right;
}
.hamburger {
    width: 30px;
    height: 26px;
    transform: rotate(0deg);
    backface-visibility: hidden;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    &:focus,
    &:hover {
        span {
            background: $bright-purple;
            width: 100% !important;
            @include breakpoint(small only) {
                background: $teal;
            }
        }
    }
    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: $teal;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        backface-visibility: hidden;
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 10px;
            width: 66%;
        }
        &:nth-child(4) {
            top: 21px;
            width: 33%;
        }
    }
    &.open {
        span {
            background: $bright-purple;
            @include breakpoint(small only) {
                background: $teal;
            }
            &:nth-child(1) {
                top: 18px;
                width: 0 !important;
                left: 50%;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
                backface-visibility: hidden;
                width: 66% !important;
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
                backface-visibility: hidden;
            }
            &:nth-child(4) {
                top: 18px;
                width: 0 !important;
                left: 50%;
            }
        }
    }
}
.menuContainer {
    background: $purple;
    width: 3000px;
    height: 3000px;
    border-radius: 50%;
    position: fixed;
    z-index: 8000;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0.001);
    backface-visibility: hidden;
    opacity: 0;
    @include breakpoint(small only) {
        height: 1000px;
        width: 1000px;
    }
    &.showMenu:after {
        content: '';
        position: fixed;
        top: calc(50% - 300px);
        left: calc(50% - 300px);
        z-index: 8400;
        width: 600px;
        height: 600px;
        border-radius: 50%;
        background: $bright-purple;
        -webkit-filter: blur(0);
        @include breakpoint(small only) {
            top: calc(50% - 230px);
            left: calc(50% - 230px);
            width: 460px;
            height: 460px;
        }
    }
}
.mobileNav {
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    backface-visibility: hidden;
    text-align: center;
    opacity: 0;
    &.fadeIn {
        z-index: 8500;
    }
    .navItems {
        margin: 0;
        padding: 0;
        li {
            min-width: 350px;
            list-style-type: none;
            padding: 10px 15px;
            @include breakpoint(small only) {
                padding: 10px 30px;
            }
            a {
                font-family: $main-font;
                width: 100%;
                line-height: 60px;
                font-size: 25px;
                color: #FFF;
                text-decoration: none;
                border: 1px solid #FFF;
                display: inline-block;
                border-radius: 4px;
                transition: border-color 0.25s ease-in, color 0.25s ease-in;
                &:hover {
                    color: $teal;
                    border-color: $teal;
                }
            }
        }
    }
    .social {
        a {
            &:hover {
                .fa {
                    color: $teal;
                }
            }
            .fa {
                font-size: 22px;
                color: #FFF;
                margin: 10px;
                padding: 10px;
                transition: color 0.3s ease;
            }
        }
    }
}
// Animations
.showMenu {
    animation: showMenu 1.5s cubic-bezier(0.02, 1.25, 1, 1) forwards;
    backface-visibility: hidden;
}
@keyframes showMenu {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(0.001);
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        transform: translateY(-50%) translateX(-50%) scale(1);
        opacity: 1;
    }
}
.hideMenu {
    animation: hideMenu 1.5s cubic-bezier(0.02, 1.25, 1, 1) forwards;
    backface-visibility: hidden;
}
@keyframes hideMenu {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(1);
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        transform: translateY(-50%) translateX(-50%) scale(0.001);
        opacity: 0;
    }
}
.fadeIn {
    animation: fadeIn 0.25s ease-in forwards;
    backface-visibility: hidden;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fadeOut {
    animation: fadeOut 0.25s ease-in forwards;
    backface-visibility: hidden;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.shakeX {
    animation: shakeX 1.5s ease;
    backface-visibility: hidden;
}
@keyframes shakeX {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}
