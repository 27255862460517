footer {
    position: relative;
    z-index: 100;
    background: $teal;
    text-align: center;
    p {
        font-family: $main-font;
        font-size: 14px;
        color: $purple;
        padding: 40px 0;
        margin: 0;
        span {
            font-size: 13px;
            display:inline-block;
        }
        i {
            color: $bright-purple;
        }
    }
}
