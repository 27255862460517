// vars
$maxPadding: 120;
$i: 0;

// top, bottom, left, right, inner margins, built procedurally
@while $i <= $maxPadding {
  .pt#{$i} { padding-top: #{$i}px; }
  .pb#{$i} { padding-bottom: #{$i}px; }
  .pl#{$i} { padding-left: #{$i}px; }
  .pr#{$i} { padding-right: #{$i}px; }

  .u-inner#{$i} { padding: #{$i}px; }

  $i: $i + 5;

  .pt0 {
    padding-top:0 !important;
  }
  .pb0 {
    padding-bottom:0 !important;
  }
}

$i: 0;

@media screen and (max-width:768px) {
  // top, bottom, left, right, inner margins, built procedurally
  @while $i <= $maxPadding {
    .tpt#{$i} { padding-top: #{$i}px; }
    .tpb#{$i} { padding-bottom: #{$i}px; }
    .tpl#{$i} { padding-left: #{$i}px; }
    .tpr#{$i} { padding-right: #{$i}px; }

    .u-tinner#{$i} { padding: #{$i}px; }

    $i: $i + 5;
  }
}

$i: 0;
// Mobile Padding
@media screen and (max-width:640px) {
  @while $i <= $maxPadding {
    .mpt#{$i} { padding-top: #{$i}px; }
  	.mpb#{$i} { padding-bottom: #{$i}px; }
    .mpl#{$i} { padding-left: #{$i}px; }
  	.mpr#{$i} { padding-right: #{$i}px; }

    .u-minner#{$i} { padding: #{$i}px; }

    $i: $i + 5;
  }

  .mpl0 {
    padding-left:0;
  }
  .mpr0 {
    padding-right:0;
  }
  .u-minner0 {
    padding:0;
  }
}

@media screen and (max-width:340px) {
  .u-inner25 {
    padding:15px;
  }
}
