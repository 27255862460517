// vars
$maxMargin: 120;
$i: 0;

// top, bottom, left, right margins, built procedurally
@while $i <= $maxMargin {
  .mt#{$i} { margin-top: #{$i}px; }
  .mb#{$i} { margin-bottom: #{$i}px; }
  .ml#{$i} { margin-left: #{$i}px; }
  .mr#{$i} { margin-right: #{$i}px; }

  $i: $i + 5;
}

.mb0 {
  margin-bottom:0 !important;
}

$i: 0;
// Mobile Margins
@media screen and (max-width:768px) {
  @while $i <= $maxMargin {
  	.tmt#{$i} { margin-top: #{$i}px; }
  	.tmb#{$i} { margin-bottom: #{$i}px; }

    $i: $i + 5;
  }

  .tmt0{
    margin-top:0 !important;
  }
  .tmb0{
    margin-bottom:0 !important;
  }
}

$i: 0;
// Mobile Margins
@media screen and (max-width:640px) {
  @while $i <= $maxMargin {
  	.mmt#{$i} { margin-top: #{$i}px; }
  	.mmb#{$i} { margin-bottom: #{$i}px; }

    $i: $i + 5;
  }

  .mmt0{
    margin-top:0 !important;
  }
  .mmb0{
    margin-bottom:0 !important;
  }
  .mml0 {
    margin-left:0 !important;
  }
  .mmr0 {
    margin-right:0 !important;
  }
}
