@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'util/mixins';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;


@import 'variables';
@import 'header';
@import 'banner';
@import 'mobile-nav';
@import 'content';
@import 'footer';
@import 'margins';
@import 'paddings';
