@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');

$ssp: 'Source Sans Pro', sans-serif;
$pt: 'PT Sans', sans-serif;

$main-font: $pt;
$second-font: $ssp;

$teal: #4CEF9B;
$purple: #1b1235;
$light-purple: #88868c;
$bright-purple: #6514f5;
