html, body {
    height: 100%
}

.img-rounded {
    border-radius: 50%;
}
.table {
    display: table;
    height: 100%;
    width: 100%;
}
.table-cell {
    display: table-cell;
    vertical-align: middle;
}
#about-me {
    background: #FFF;
    position: relative;
    z-index: 100;
    .my-picture {
        width: 130px;
        float: right;
        @include breakpoint(small only) {
            width: 100%;
        }
        img {
            @include breakpoint(small only) {
                width: 50%;
            }
        }
    }
    .my-name {
        width: calc(100% - 160px);
        float: left;
        @include breakpoint(small only) {
            width: 100%;
            margin-top: 30px;
        }
    }
    a {
        &:hover {
            .fa {
                color: $bright-purple;
            }
        }
        .fa {
            font-size: 22px;
            color: $teal;
            margin: 10px 10px 0;
            padding: 10px;
            transition: color 0.3s ease;
            &:first-child {
                margin-left: 0;
                padding-left: 0;
            }
        }
    }
    h2 {
        font-family: $second-font;
        color: $purple;
        font-weight: 300;
        font-size: 34px;
        margin: 0;
    }
    h3 {
        font-family: $second-font;
        color: $bright-purple;
        font-weight: 700;
        font-size: 26px;
        margin: 0;
    }
    hr {
        border-color: $purple;
        margin: 30px 0;
    }
    p {
        font-family: $second-font;
        color: $purple;
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        span {
            position: relative;
            z-index: 5100;
            &:after {
                content: '';
                position: absolute;
                z-index: 5000;
                left: 0;
                right: 0;
                bottom: 3px;
                border-bottom: 1px solid $bright-purple;
                width: 100%;
            }
        }
    }
}
#work {
    background: $purple;
    position: relative;
    z-index: 100;
    h2 {
        font-family: $second-font;
        color: #FFF;
        font-weight: 300;
        font-size: 34px;
        line-height: 34px;
        margin: 0 0 60px;
        text-align: center;
        @include breakpoint(medium only) {
            line-height: 38px;
        }
        strong {
            font-size: 16px;
            color: $light-purple;
        }
        span {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                z-index: 5000;
                left: 0;
                right: 0;
                bottom: 3px;
                border-bottom: 1px solid #6514f5;
                width: 100%;
            }
        }
    }
    h3 {
        font-family: $second-font;
        color: $teal;
        font-weight: 400;
        font-size: 26px;
        margin: 0 0 20px;
    }
    hr {
        border-color: $bright-purple;
        margin: 75px 0 60px;
    }
    p {
        font-family: $second-font;
        color: #FFF;
        font-weight: 300;
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 40px;
        a,
        span {
            font-family: $second-font;
            color: $teal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            display: inline-block;
            margin-top: 10px;
            i {
                font-size: 16px;
            }
        }
    }
}
.scroll-container {
    overflow: hidden;
    width: 100%;
    height: 350px;
    .scroll-work {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
.slideInUp {
    animation-name: slideInUp;
    backface-visibility: hidden;
}
@include breakpoint(small only) {
    .mtext-center {
        text-align: center;
    }
}
