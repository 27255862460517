#bg-video {
	background: linear-gradient(rgba(17,14,26,.5), rgba(101,20,245,.5));
	// background: linear-gradient(rgba(101,20,245,.5), rgba(17,14,26,.5));
	overflow: hidden;
	min-height: 100vh;
	&:before {
		content:'';
		position: absolute;
		z-index: -1;
		top:0;
		bottom:0;
		left:0;
		right:0;
		background: linear-gradient(rgba(17,14,26,.5), rgba(101,20,245,.5));
	}
	video {
		position: fixed !important;
		@include breakpoint(small only){
			position: absolute !important;
		}
	}
	@include breakpoint(small only){
		background-image: linear-gradient(rgba(17,14,26,.5), rgba(101,20,245,.5));
		background-repeat: no-repeat;
		background-position: center center;
	}
}
#banner {
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	backface-visibility: hidden;
	position: fixed;
	z-index: 90;
	visibility: none;
	@include breakpoint(small only){
		position: absolute;
	}
	#banner-content {
		position: relative;
		padding: 30px 0;
		border: 1px solid rgba(76,239,155,0.5);
		border-radius: 4px;
		&:after {
			content: '';
			position: absolute;
			bottom: -21px;
			right: 30%;
			border-left: 40px solid transparent;
			border-right: 0 solid transparent;
			border-top: 20px solid rgba(76,239,155,0.5);
		}
		h1 {
			font-family: $main-font;
			font-weight: 400;
			font-size: 40px;
			color: #FFF;
			text-align: center;
			letter-spacing: 1px;
			margin-bottom: 0;
			strong {
				font-weight: 700;
				color: $teal;
			}
			span {
				display: inline-block;
			}
		}
	}
}
@keyframes rubberBand {
	0% {
		transform: scale(1);
	}
	30% {
		transform: scaleX(1) scaleY(0.75);
	}
	40% {
		transform: scaleX(0.75) scaleY(1.25);
	}
	60% {
		transform: scaleX(1.15) scaleY(0.85);
	}
	100% {
		transform: scale(1);
	}
}
.rubberBand {
	animation: rubberBand 1.5s cubic-bezier(0.49, 1.16, 1, 1);
	backface-visibility: hidden;
}
#scroll {
	width: 25px;
	height: 130px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	backface-visibility: hidden;
	visibility: none;
	@include breakpoint(small only){
		position: absolute !important;
	}
	svg {
		animation: scrollShake 1.5s;
		animation-iteration-count:infinite;
		animation-delay: 4s;
	}
	.mouse-body {
		fill: none;
		stroke: rgba(76,239,155,0.5);
		stroke-width: 2.5367;
		stroke-miterlimit: 10;
	}
	.mouse-arrow {
		fill: rgba(76,239,155,0.5);
	}
}

@keyframes scrollShake {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(0);
	}
}
