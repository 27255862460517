body {
    overflow-x: hidden;
}
header {
    position: fixed;
    width: 100%;
    // background: $purple;
    z-index: 8600;
    transition: background 1s ease;
    &.mobileHeader {
        background: $bright-purple;
    }
    .nav {
        text-align: right;
        margin: 0;
        li {
            display: inline-block;
            margin: 32px 10px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 14px;
                font-family: $main-font;
                font-weight: 900;
                line-height: 14px;
                color: $teal;
                text-transform: uppercase;
                border: 1px solid $teal;
                padding: 10px 20px 8px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
a {
    &:hover {
        #logo-td {
            .logo-stroke {
                fill: $bright-purple;
            }
            .logo-center {
                fill: transparent;
            }
        }
    }
    #logo-td {
        margin: 18px 0;
        width: 150px;
        .logo-stroke {
            fill: transparent;
            transition:fill 0.3s ease;
        }
        .logo-center {
            fill: $teal;
            transition:fill 0.3s ease;
        }
    }
}
